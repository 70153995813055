import React, {useState} from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { changeEmail, changeSubject, changeEmailBody, submitEmail, selectSubmissionStatus } from './contactSlice';
import styles from './Contact.module.css';

import Button    from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Icon      from '@material-ui/core/Icon';
import Snackbar  from '@material-ui/core/Snackbar';
import MuiAlert  from '@material-ui/lab/Alert';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}  

const fieldStateTemplate = {
    value: '',
    error: false,
    dirty: false,
    helper: ''
}

export function Contact() {
    const submissionStatus = useAppSelector(selectSubmissionStatus);
    const dispatch = useAppDispatch();

    const [email,   setEmail]   = useState(fieldStateTemplate);
    const [subject, setSubject] = useState(fieldStateTemplate);
    const [text,    setText]    = useState(fieldStateTemplate);

    const [passedCaptcha, setPassedCaptcha] = useState(false);
    const [showSuccess, setShowSuccess]     = useState(true);
    const [showFailure, setShowFailure]     = useState(true);

    const handleEmail = (e: string) => {
        let newState = {};

        if (! e.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            newState = {
                dirty: true,
                error: true,
                helper: 'Invalid email format'
            };
        } else {
            newState = {
                value: e,
                dirty: true,
                error: false,
                helper: ''
            };
            dispatch(changeEmail(e));
        }

        setEmail({...email, ...newState});
    }

    const handleSubject = (s: string) => {
        let newState = {};

        if (s.length === 0) {
            newState = {
                dirty: true,
                error: true,
                helper: 'Subject is required'
            };
        } else {
            newState = {
                value: s,
                dirty: true,
                error: false,
                helper: ''
            };
            dispatch(changeSubject(s));
        }

        setSubject({...email, ...newState});
    }

    const handleText = (t: string) => {
        let newState = {};

        if (t.length === 0) {
            newState = {
                dirty: true,
                error: true,
                helper: 'Text is required'
            };
        } else {
            newState = {
                value: t,
                dirty: true,
                error: false,
                helper: ''
            };
            dispatch(changeEmailBody(t));
        }

        setText({...email, ...newState});
    }

    const captchaSucceeded = () => {
        setPassedCaptcha(true);
    }

    const captchaExpired = () => {
        setPassedCaptcha(false);
    }

    const dirtyEmail = () => {
        setEmail({...email, ...{dirty: true}});
    }

    const dirtySubject = () => {
        setSubject({...subject, ...{dirty: true}});
    }

    const dirtyText = () => {
        setText({...text, ...{dirty: true}});
    }

    const handleCloseSuccess = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setShowSuccess(false);      
    }

    const handleCloseFailure = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setShowFailure(false);      
    }

    return (
        <Container maxWidth="sm">
            <form autoComplete="off" className={styles.verticalSpacing}>
                <TextField id="email" label="Email" required
                    placeholder="you@your.org"
                    fullWidth
                    autoFocus
                    tabIndex={1}
                    error={email.error}
                    helperText={email.helper}
                    disabled={submissionStatus !== 'initialized'}
                    onFocus={() => dirtyEmail() }
                    onChange={ e => handleEmail(e.target.value) } />

                <TextField id="subject" label="Subject" required
                    placeholder="Your subject here"
                    fullWidth
                    tabIndex={2}
                    error={subject.error}
                    helperText={subject.helper}
                    disabled={submissionStatus !== 'initialized'}
                    onFocus={ () => dirtySubject() }
                    onBlur={ e => handleSubject(e.target.value) }
                    onChange={ e => handleSubject(e.target.value) } />

                <TextField id="text" label="Email Text"
                    required multiline rows={5}
                    fullWidth
                    tabIndex={3}
                    placeholder="What do you need?"
                    error={text.error}
                    helperText={text.helper}
                    disabled={submissionStatus !== 'initialized'}
                    onFocus={ () => dirtyText() }
                    onBlur={ e => handleText(e.target.value) }
                    onChange={ e => handleText(e.target.value) } />

                <div className={styles.verticalSeparation}>
                    <Button className={styles.floatRight}
                        variant="contained"
                        color="secondary"
                        tabIndex={5}
                        endIcon={<Icon>send</Icon>}
                        disabled={!(submissionStatus === 'initialized' && (email.dirty && !email.error) && (subject.dirty && !subject.error) && (text.dirty && !text.error) && passedCaptcha)}
                        onClick={() => dispatch(submitEmail({
                            'sender':  email.value,
                            'subject': subject.value,
                            'body':    text.value
                        }))}>

                        Send

                    </Button>

                    <ReCAPTCHA
                        tabindex={4}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || 'Set your environment variables!'}
                        onChange={ e => captchaSucceeded() } 
                        onExpired={ () => captchaExpired() } />
                </div>
            </form>

            <Snackbar open={showSuccess && submissionStatus === 'succeeded' } autoHideDuration={8000} onClose={handleCloseSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSuccess} severity="success">Email sent!</Alert>
            </Snackbar>
            <Snackbar open={showFailure && submissionStatus === 'failed' } autoHideDuration={8000} onClose={handleCloseFailure} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseFailure} severity="error">Error encountered, notification not sent</Alert>
            </Snackbar>
        </Container>
    );
}
